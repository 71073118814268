
import {
  Vue,
  Component,
  Mixins,
  InjectReactive,
  Inject,
} from "vue-property-decorator";
import FindAllGunBoxMixin from "../mixins/find-all-gun-box.mixin";
import { useFindAll } from "../graphql/use-find-all";
import { BattleFragment } from "@/graphql/fragments";
import { IBattle } from "@/interfaces/battle.interface";
import gql from "graphql-tag";
import TopAppBar from "@/components/base/TopAppBar.vue";
import BattleCard from "@/components/battles/BattleCard.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import CreateBattleDialog from "@/components/battles/CreateBattleDialog.vue";

@Component({
  components: {
    TopAppBar,
    BattleCard,
    GradientBtn,
    ScrollFetchMore,
    CreateBattleDialog,
  },
  apollo: {
    battles: {
      query: useFindAll("Battle", BattleFragment),
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.findAllBattle || [];
      },
      variables() {
        return {
          options: {
            populate: "users",
            sort: "completed -_id",
            limit: 25,
          },
          filter: {
            ...(this.showOnlyMyBattles
              ? { userIds: { eq: this.user._id } }
              : {}),
          },
        };
      },
      subscribeToMore: [
        {
          document: gql`
            subscription {
              subscribeBattleAdded {
                ...Battle
              }
            }
            ${BattleFragment}
          `,
          updateQuery(
            previousResult,
            {
              subscriptionData: {
                data: { subscribeBattleAdded: value },
              },
            }
          ) {
            const key = Object.keys(previousResult)[0];

            if (
              (this.showOnlyMyBattles &&
                (value.userIds || []).includes(this.user._id)) ||
              !this.showOnlyMyBattles
            ) {
              previousResult[key].unshift(value);
            }

            return {
              [key]: previousResult[key],
            };
          },
        },
        {
          document: gql`
            subscription {
              subscribeBattleRemoved {
                _id
              }
            }
          `,
          updateQuery: (
            previousResult,
            {
              subscriptionData: {
                data: { subscribeBattleRemoved: value },
              },
            }
          ) => {
            const key = Object.keys(previousResult)[0];
            const index = previousResult[key].findIndex(
              ({ _id }) => _id === value._id
            );

            if (index === -1) {
              return previousResult;
            }

            previousResult[key].splice(index, 1);

            return {
              [key]: previousResult[key],
            };
          },
        },
        {
          document: gql`
            subscription {
              subscribeBattleUpdated {
                ...Battle
              }
            }
            ${BattleFragment}
          `,
          updateQuery(
            previousResult,
            {
              subscriptionData: {
                data: { subscribeBattleUpdated: value },
              },
            }
          ) {
            const key = Object.keys(previousResult)[0];
            const index = previousResult[key].findIndex(
              ({ _id }) => _id === value._id
            );

            if (index === -1) {
              return previousResult;
            }

            this.$set(previousResult[key], index, value);

            return {
              [key]: previousResult[key],
            };
          },
        },
      ],
    },
  },
})
export default class BattleLobby extends Mixins(FindAllGunBoxMixin) {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;

  private battles: IBattle[] = [];
  private showOnlyMyBattles = false;
  private showCreateBattleDialog = false;
}

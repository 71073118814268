
import {
  Component,
  Inject,
  InjectReactive,
  Mixins,
  VModel,
} from "vue-property-decorator";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import FindAllGunBoxMixin from "@/mixins/find-all-gun-box.mixin";
import Price from "@/components/base/Price.vue";
import * as sumBy from "lodash/sumBy";
import BattleBox from "@/components/battles/BattleBox.vue";
import { GunBoxEntity } from "@/entities/gun-box.entity";
import { AuthUserEntity } from "@/entities/user.entity";
import gql from "graphql-tag";
import { BattleFragment } from "@/graphql/fragments";

@Component({
  components: {
    Price,
    BattleBox,
  },
})
export default class CreateBattleDialog extends Mixins(FindAllGunBoxMixin) {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @VModel({ type: Boolean }) show!: boolean;

  private selected: Array<GunBoxEntity> = [];
  private userCount = 2;
  private loading = false;
  private maxSelectedCount = Object.freeze(10);

  private get price() {
    return Number(sumBy(this.selected, "price").toFixed(2));
  }

  private get overprice() {
    const v = Number(
      (this.price - (this.user ? this.user.balance : 0)).toFixed(2)
    );

    return v > 0 ? v : 0;
  }

  private select(box: GunBoxEntity) {
    if (this.selected.length + 1 > this.maxSelectedCount) {
      return;
    }

    this.selected.push(box);
  }

  private deselect(index: number) {
    this.selected.splice(index, 1);
  }

  private async createBattle() {
    this.loading = true;

    const { data } = await this.$apollo.mutate({
      mutation: gql`
        mutation ($input: CreateBattleInput!) {
          createBattle(input: $input) {
            ...Battle
          }
        }
        ${BattleFragment}
      `,

      variables: {
        input: {
          gunBoxIds: this.selected.map(({ _id }) => _id),
          userCount: this.userCount,
        },
      },
    });

    if (data) {
      this.show = false;
      await this.$router.push(`/battle/${data.createBattle._id}`);
    }
  }
}
